import React from "react";
import { Container } from "react-bootstrap";
import { useIntl } from "gatsby-plugin-intl";
import styled from "styled-components";

import { StyledButton } from "../Button";

const CategoryItem = styled.div`
  position: relative;
  display: inline-block;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    transition: all 0.3s ease-in-out;
    transform: scaleX(0);
    transform-origin: left;
  }
  &.active,
  &:hover {
    &:after {
      transform: scaleX(1);
    }
  }
`;

const CategoryList = ({ categories, currentCategory, setCurrentCategory }) => {
  const intl = useIntl();

  return (
    <Container className="py-5 my-5">
      <div className="mb-3">
        <CategoryItem className={!currentCategory ? "active" : ""}>
          <StyledButton
            className="d-block h5 font-weight-normal text-left"
            onClick={() => {
              setCurrentCategory(null);
            }}
          >
            0. {intl.formatMessage({ id: "view_all" })}
          </StyledButton>
        </CategoryItem>
      </div>

      {categories.map((category, index) => (
        <div key={category.uid} className="mb-3">
          <CategoryItem
            className={category.uid === currentCategory ? "active" : ""}
          >
            <StyledButton
              className="d-block h5 font-weight-normal text-left"
              onClick={() => {
                setCurrentCategory(category.uid);
              }}
            >
              {index + 1}. {category.data.title.text}
            </StyledButton>
          </CategoryItem>
        </div>
      ))}
    </Container>
  );
};

export default CategoryList;
