import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link, useIntl } from "gatsby-plugin-intl";

const Category = styled.span`
  color: ${props => props.theme.colors.gray};
  &:before {
    content: "(";
  }
  &:after {
    content: ")";
  }
`;

const ProjectGrid = ({ projects }) => {
  const intl = useIntl();
  const projectColumns = projects.reduce(
    (accumulator, currentItem, index) => {
      if ((index + 1) % 2 === 0) {
        accumulator.rightCol = [
          ...accumulator.rightCol,
          { ...currentItem, index },
        ];
      } else {
        accumulator.leftCol = [
          ...accumulator.leftCol,
          { ...currentItem, index },
        ];
      }
      return accumulator;
    },
    {
      leftCol: [],
      rightCol: [],
    }
  );

  return (
    <Container>
      {projects.length === 0 && (
        <h2 className="text-center h1 position-relative">
          {intl.formatMessage({ id: "no_projects_found" })}
        </h2>
      )}
      <Row>
        <Col md={5}>
          {projectColumns.leftCol.map(project => {
            const img = getImage(project.cover.localFile.childImageSharp);
            return (
              <Link
                to={`/proyectos/${project.slug}`}
                key={project.slug}
                className="mb-5 d-block"
              >
                <GatsbyImage
                  image={img}
                  alt={project.cover.alt || ""}
                  className="mb-4"
                />
                {project.category && (
                  <Category className="mb-3 d-block">
                    {project.categoryIndex + 1}.{" "}
                    {project.category.data.title.text}
                  </Category>
                )}
                <h2 className="h4 text-dark">{project.title}</h2>
              </Link>
            );
          })}
        </Col>
        <Col md={5} className="ml-auto">
          {projectColumns.rightCol.map(project => {
            if (!project.cover.localFile) {
              return null;
            }

            const img = getImage(project.cover.localFile.childImageSharp);
            return (
              <Link
                to={`/proyectos/${project.slug}`}
                key={project.slug}
                className="mb-5 d-block"
              >
                <GatsbyImage
                  image={img}
                  alt={project.cover.alt || ""}
                  className="mb-4"
                />
                {project.category && (
                  <Category className="mb-3 d-block">
                    {project.categoryIndex + 1}.{" "}
                    {project.category.data.title.text}
                  </Category>
                )}
                <h2 className="h4 text-dark">{project.title}</h2>
              </Link>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectGrid;
