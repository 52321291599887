import React, { useState } from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { Container } from "react-bootstrap";
import styled from "styled-components";

import Layout from "../components/Layout";
import CategoryList from "../components/projects/CategoryList";
import ProjectGrid from "../components/projects/ProjectGrid";
import { StyledButton } from "../components/Button";
import Shape from "../images/shape-3.inline.svg";

const StyledShape = styled(Shape)`
  position: absolute;
  width: 150px;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
`;

const ProjectsPage = ({ data }) => {
  const { allPrismicServicios, allPrismicProyectos } = data;
  const intl = useIntl();
  const [page, setPage] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(null);

  const itemsPerPage = 6;
  const totalPages = Math.ceil(allPrismicProyectos.nodes.length / itemsPerPage);
  const projects = allPrismicProyectos.nodes
    .filter(project => {
      if (!currentCategory) {
        return true;
      }
      return project.data.category.document?.uid === currentCategory;
    })
    .slice(0, itemsPerPage * (page + 1));

  return (
    <Layout>
      <CategoryList
        categories={allPrismicServicios.nodes}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
      />
      <ProjectGrid
        projects={projects.map(project => ({
          slug: project.uid,
          cover: project.data.cover,
          title: project.data.title.text,
          category: allPrismicServicios.nodes.find(
            service => service.uid === project.data.category.document?.uid
          ),
          categoryIndex: allPrismicServicios.nodes.findIndex(
            service => service.uid === project.data.category.document?.uid
          ),
        }))}
      />
      {page + 1 < totalPages && (
        <Container className="text-center mt-5 position-relative pb-5">
          <StyledShape />
          <StyledButton
            className="position-relative pb-4"
            onClick={() => {
              setPage(prevPage => prevPage + 1);
            }}
          >
            {intl.formatMessage({ id: "view_more" })}
          </StyledButton>
        </Container>
      )}
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    allPrismicServicios(filter: { lang: { eq: $language } }) {
      nodes {
        uid
        data {
          title {
            text
          }
        }
      }
    }
    allPrismicProyectos(filter: { lang: { eq: $language } }) {
      nodes {
        uid
        data {
          cover {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          title {
            text
          }
          category {
            document {
              ... on PrismicServicios {
                uid
              }
            }
          }
        }
      }
    }
  }
`;

export default ProjectsPage;
